import React, { useState } from 'react'
import { Button, Col, Container, Row } from 'reactstrap'

const FAQ = () => {
    const [state, setState] = useState("parents")
    const Parents = () => (
      <Row>
                            {[
                            (<p className='sub-text text-grey font-weight-light mb-0'><b>What are Pouch prepaid cards?</b> <br />Pouch cards are uniquely cheap, timely and educationally Verve reloadable card offering created particularly for families</p>),
                            (<p className='sub-text text-grey font-weight-light mb-0'><b>How do Pouch cards work?</b> <br />Parents get a pouch token from their child’s school. Parents downloads the pouch app, create a wallet , fund a wallet, and order a card. With the card, the child can use it to buy items in and out of school</p>),
                            (<p className='sub-text text-grey font-weight-light mb-0'><b>How do i get started ?</b> <br />See your child’s school to get a token or tell your child’s school to contact us at schools@getpouch.africa</p>),
                            (<p className='sub-text text-grey font-weight-light mb-0'><b>What are Pouch prepaid cards better than cash?</b> <br />It is safe, more convenient, transparent, mobile, and educational</p>),
                            (<p className='sub-text text-grey font-weight-light mb-0'><b>What are Pouch prepaid cards better than credit cards or bank debit cards?</b> <br />With Pouch, parents can get the cards easily within 24hrs, they are also in control, they can literally determine all that happens on the cards. Parents can set spending limits, enable or disable accounts and even rewards chores.</p>)
                        ].map((data) => (
                            <div className="d-flex align-items-center my-3">
                                {/* <div>

                                </div> */}
                                <img width={20} src={require("../Images/pouch.svg").default} alt="" className="img-fluid mr-3" />
                                <div>
                                    {data}
                                </div>
                            </div>
                        ))}
                        </Row>
    )
    const Schools = () => (
      <Row>
                            {[
                            (<p className='sub-text text-grey font-weight-light mb-0'><b>Why is Pouch better than cash in schools?</b> <br />It prevents loss of cash in the school premises, its more convenient and easy to use. It allows students to be better spenders and savers</p>),
                            (<p className='sub-text text-grey font-weight-light mb-0'><b>When will your school get the attendant report?</b> <br /> The school will have instant access to daily attendant reports of students.</p>),
                            (<p className='sub-text text-grey font-weight-light mb-0'><b>How can your school apply?</b> <br />By filling the form here or reaching out to <b>schools@getpouch.africa</b></p>),
                            (<p className='sub-text text-grey font-weight-light mb-0'><b>How will pouch be integrated in your schools?</b> <br />Pouch will provide the attendance device and point of sales to your school</p>),
                            (<p className='sub-text text-grey font-weight-light mb-0'><b>How will parents register?</b> <br />The school will be given token to issue out to parent of each student.</p>)
                        ].map((data) => (
                            <div className="d-flex align-items-center my-3">
                                {/* <div>

                                </div> */}
                                <img width={20} src={require("../Images/pouch.svg").default} alt="" className="img-fluid mr-3" />
                                <div>
                                    {data}
                                </div>
                            </div>
                        ))}
                        </Row>
    )
    return (
        <div>
            <Container>
                <Row>
                    <Col md="12">
                        <h3 className="main-header text-grey">Frequently Asked Questions</h3>
                    </Col>
                </Row>

                <Row className='align-items-center my-3'>
                    <Col md="6">
                        <div className='d-flex align-items-center justify-content-center'>
                            <div className='faq-btn-div'>

                                {[
                                    "parents",
                                    "schools"
                                ].map((data, i) => (
                                    <Button key={i} onClick={() => setState(data)} color={`${data === state ? "primary" : "light-white"}`} className={`${data === state ? "text-white" : "text-dark"} text-capitalize font-weight-bolder py-3 px-5 mb-3 w-100 d-block`}>{data}</Button>
                                ))}
                            </div>

                        </div>
                    </Col>
                    <Col md="6">
                        { state == 'parents' ? <Parents /> : <Schools /> }
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default FAQ