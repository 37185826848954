import React from 'react'

const Logo: React.FC<any> = (props) => {
  let { style, logoType, ...otherProps } = props
  return <img loading='lazy' alt="logo" className='logo'
    src={require(`assets/images/png/Logo.png`)}
    style={style}
    {...otherProps} />
}

export default Logo;