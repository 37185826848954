import React from 'react'
import { Col, Container, Row } from 'reactstrap'

const KnowComponent: React.FC<any> = () => {
    return (
        <div className='my-5 py-5'>
            <Container>
                <Row className='align-items-center'>
                    <Col lg="5" md="6" className="d-md-none">
                        <div className="iphone">
                            <img src={require('../../../assets/images/png/notification.png')} alt="" className='img-fluid' />
                        </div>
                    </Col>
                    <Col lg="7" md="6">
                        <h3 className='main-header text-dark'>Know when your child arrives or exits the school</h3>
                        <p className='sub-text text-grey my-5'>You don’t have to worry about when your child has gotten or left school, Pouch gives you <span className="text-primary"> real- time notifications</span></p>
                    </Col>
                    <Col lg="5" md="6" className="d-none d-md-flex">
                        <div className="iphone">
                            <img src={require('../../../assets/images/png/notification.png')} alt="" className='img-fluid' />
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className='align-items-center money-habits my-5'>
                    <Col lg="6" md="6">
                        <div className="iphone">
                            <img src={require('../../../assets/images/svg/iPhone-2.svg').default} alt="" className='img-fluid mt-md-n5' />
                        </div>
                    </Col>
                    <Col lg="6" md="6">
                        <h3 className='main-header text-dark'>Build good money habits early</h3>
                        <p className='sub-text text-grey my-5'>By starting early, you can help your child build <span className="text-primary"> good spending and saving habits</span> that will last a lifetime. Your child can pratically learn real- world financial skills that help them become spenders and savers</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default KnowComponent