import React from 'react'
import { Col, Container, Row } from 'reactstrap'

const BlueSection = () => {
    return (
        <div className='bg-primary my-5'>
            <Container>
                <Row>
                    {[
                        {
                            header: "Automate reward for customized chores",
                            content: (<p>
                                Let your <span className="text-secondary"> kids earn more rewards for tasks</span> well done and good grades in school
                            </p>)
                        },
                        {
                            header: "Real- time notifications",
                            content: (<p>
                                You can <span className="text-secondary"> keep an eye on spending </span>by knowing where and when spend; this gives a helping hand in budgetting.
                            </p>)
                        },
                        {
                            header: "Smart spending doesn’t go unnoticed ",
                            content: (<p>
                                Parents turn spending into saving for their kid’s future with <span className="text-secondary"> cash back on all purchases</span>
                            </p>)
                        }
                    ].map((data, i) => (
                        <Col md="4" key={i}>
                        <div className="dotted-lines my-4">
                                <h3>{data.header}</h3>
                                {data.content}
                        </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    )
}

export default BlueSection