import React from 'react'
import { Col, Container, Row } from 'reactstrap'

const HowItWorks = () => {
    return (
        <div className='mb-5 pb-5'>
            <Container>
                <Row>
                    <Col md="12">
                        <h3 className="main-header text-dark text-center">How Pouch  Works</h3>
                    </Col>
                </Row>
                <Row className='my-5'>
                    <Col md="4" sm="12">
                        <h3 className="main-header text-grey">A Pocket Money App</h3>
                        <p className="sub-text text-grey">Parents deposit pocket money for a child in a digital wallet(s)</p>
                    </Col>
                    <Col md="4" sm="12" className='text-center'>
                        <img src={require("../Images/plus.svg").default} alt="" className="img-fluid" />
                    </Col>
                    <Col md="4" sm="12" >
                        <h3 className="main-header text-grey">Prepaid Debit Card</h3>
                        <p className="sub-text text-grey">Each child can access the wallets to buy items within or outside school premises</p>
                    </Col>
                </Row>
                <Row className='align-items-center '>
                    <Col md="6" className="d-none d-md-flex">
                        <img src={require("../Images/iPhone-3.svg").default} alt="" className="img-fluid" />
                    </Col>
                    <Col md="6">
                        <img src={require("../Images/verve.svg").default} alt="" className="img-fluid" />
                    </Col>
                </Row>
                <Row>
                    {[
                        {
                            number: "10",
                            text: "Schools"
                        }, {
                            number: "5,000",
                            text: "Parents"
                        }, {
                            number: "10,000",
                            text: "Kids & Teens"
                        }
                    ].map((data) => (
                        <Col md="4" key={data.number}>
                            <div className="metric-card text-center my-5">
                                <div>
                                    <h3 className='text-primary'>{data.number}+</h3>
                                    <p className="text-grey">{data.text}</p>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
            <Container>
                <Row className='align-items-center my-3'>
                    <Col md="6" className="d-md-none">
                        <div className="bg-light-grey grey-bordered">
                            <img src={require("../Images/flexible-img.svg").default} alt="" className="img-fluid mr-2" />

                        </div>
                    </Col>
                    <Col md="6">
                        <h6 className="main-header text-primary">
                            Flexible controls
                        </h6>
                        {[
                            "Automate scheduled payment of your kid’s pocket money",
                            "Your child can only spend within your approved limits",
                            "You can enable and disable the accounts",
                            "Know where and how much your child spends with instant notifications",
                            "Approve cash withdrwals"
                        ].map((data) => (
                            <div className="d-flex my-3">
                                <div>
                                    <img width={10} src={require("../Images/pouch.svg").default} alt="" className="img-fluid mr-2" />
                                </div>
                                <div>
                                    <p className="sub-text text-grey font-weight-light mb-0">{data}</p>
                                </div>
                            </div>
                        ))}
                    </Col>
                    <Col md="6" className="d-none d-md-flex">
                        <div className="bg-light-grey grey-bordered">
                            <img src={require("../Images/flexible-img.svg").default} alt="" className="img-fluid mr-2" />

                        </div>
                    </Col>
                </Row>
                <Row className='align-items-center my-3'>
                    <Col md="6">
                        <div className="bg-secondary grey-bordered">
                            <img src={require("../../../assets/images/png/savings.png")} alt="" className="img-fluid mr-2" />
                        </div>
                    </Col>
                    <Col md="6">
                        <h3 className="text-primary main-header">Save for future goals</h3>
                        <p className="text-grey sub-text font-weight-light">
                            You can save for back- to- school items, gifts and more. Your kids can also learn how to save and earn 1% on their savings. You can plan better, budget better, and feel better
                        </p>
                    </Col>
                </Row>
                <Row className='align-items-center my-3'>
                    <Col md="6">
                        <h3 className="text-primary main-header">No more carrying cash</h3>
                        <p className="text-grey sub-text font-weight-light">
                        Great to know that your child will always have money whenever they need it. Pouch is a safe and secure wallet. You can create multiple wallets and share money between wallets
                        </p>
                    </Col>
                    <Col md="6">
                        <div className="bg-light-grey grey-bordered">
                            <img src={require("../../../assets/images/png/cashless.png")} alt="" className="img-fluid mr-2" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default HowItWorks