import React from 'react'
import Header from '../Landing/Components/Header';
import PolicyDocument from './Components/PolicyDocument';

const Policy: React.FC<any> = () => {
    return (
        <div id='landing'>
            <Header />

            <PolicyDocument />
        </div>
    )
}

export default Policy;