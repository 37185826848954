import React from 'react'
import BlueSection from './Components/BlueSection';
import FAQ from './Components/FAQ';
import Header from './Components/Header';
import HowItWorks from './Components/HowItWorks';
import KnowComponent from './Components/KnowComponent';
import WhoWeAre from './Components/WhoWeAre';

const Landing: React.FC<any> = () => {
    return (
        <div id='landing'>
            <Header />
            <KnowComponent />
            <HowItWorks />
            <BlueSection />
            <FAQ />
            <WhoWeAre />
        </div>
    )
}

export default Landing;