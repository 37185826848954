import React from 'react'
import { Col, Container, Row } from 'reactstrap'

const WhoWeAre = () => {
  return (
    <div className='my-5 py-5 who-we-are'>
            <Container>
                <Row>
                    <Col sm="12">
                        <h3 className=" text-center main-header txt-dark">Who we are</h3>
                    </Col>
                    {[
                        "Pouch is a pocket- money app for children between the ages of 7 and 16 years that is within the parent’s control, and it raises each child to be financially empowered",
                        "Pouch is an educated fintech product of GetPouch Technology Ltd."
                    ].map((data, i)=>(
                        <Col sm="12" key={i}>
                            <p className='sub-text text-grey my-3'>{data}</p>
                        </Col>
                    ))}
                </Row>
            </Container>
    </div>
  )
}

export default WhoWeAre