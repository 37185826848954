import Logo from 'Components/Logo';
import React from 'react'
import { Button, Col, Container, Row } from 'reactstrap';

const Header: React.FC<any> = () => {
    return (
        <div id='header'>
            <Container fluid>
                <Row>
                    <Col sm="12">
                        <Logo />
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className='align-items-center'>
                    <Col md="6">
                        <h3 className='main-header text-white'>Take the stress out of giving cash to Kids and Teens </h3>
                        <p className='sub-text text-semi-white'>Manage all pocket money in one place. Teach your child money management skills with Pouch. Perfect for Kids and teens teaching them how to <span className="text-secondary">earn, save and spend</span></p>
                        <a href="https://forms.gle/jKSbfoDHefQcNyT37" target="_blank" className='btn py-1 px-4 my-3'>Get Started</a>
                    </Col>
                    <Col className="d-none d-md-flex" md="6">
                        <img src={require('assets/images/svg/CardLady.svg').default} alt="" className='img-fluid' />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Header;