import React from 'react'
import {Col, Container, Row} from 'reactstrap'

const PolicyDocument = () => {
    return (
        <div className='my-5 py-5 who-we-are'>
            <Container>
                <Row>
                    <Col sm="12">
                        <h3 className=" text-center main-header txt-dark">Privacy Policy</h3>
                    </Col>

                    <div>
                        <div>
                            Welcome to the GetPouch's Privacy Policy (“Policy”).
                        We value the trust you place on getpouch.africa (“Our”) website and application platforms on
                        Google Play Store and iOS built to host, manage, and deploy school pocket-money and attendance
                        to household via personal computers, smartphones and tablets that is why we insist upon the
                        highest financial literacy standards and customer’s information privacy.
                        </div>

                        <div>
                            This page informs you of Our policies regarding the collection, use and disclosure of your
                        personal data when you visit, access, browse through and/or use Our Website or Mobile
                        Application (“Platform”) and when you use Our tokens, SD cards, or other storage or transmitting
                        device (“Products”). Please read the following statement to learn about our information
                        gathering and dissemination practises. Note: Our privacy policy is subject to change at any time
                        without notice. To make sure you are aware of any changes, please review this policy
                        periodically.
                        </div>
                        <br/>
                        <h4><u>Contact Detail</u></h4>
                        <div>Our full details are</div>
                        <div>Full name of legal entity: GetPouch Technology Limited Email address: hello@getpouch.africa</div>
                        <div>Postal address: 1B Johnson Street, off Bode Thomas, Surulere, Lagos. Telephone number: +234 907
                        350 0478</div>
                        <br/>
                        <h4><u>Consent</u></h4>
                        <div>
                            By accessing or using Our services or Products, you agree to the collection and use of
                        information in accordance with this Notice. Once you provide consent, you may change your mind
                        and withdraw the consent at any time by contacting us at hello@getpouch.africa, but please note
                        that consent withdrawal will not affect the lawfulness of any processing carried out before you
                        withdraw your consent.
                        </div>

                        <br/>
                        <h4><u>The Data We Collect About You</u></h4>
                        <div>
                            <div>When signing up on Our Platform, we collect information about you. This information may include:</div>

                        <ul>
                            <li>Profile data, such as your name, username or alias, e-mail address, mailing address, location,
                        name of school, class, age, and phone number;
                            </li>

                        <li>Financial data, such as your card information, billing address, and payment options may also be
                        required by our payment service providers;
                        </li>

                        <li>Technical information, such as the type of mobile device and internet browser you use, your
                        computer IP address, data about the pages you access, mobile device ID or unique identifier,
                        statistics on page views, standard Web log data, still and moving images, etc;</li>

                        <li>Marketing data, such as users’ feedback; and</li>

                        <li>Usage data, such as time spent on Our application, when application was opened, device data and
                        learning statistics (time spent learning, lessons viewed, test scores, etc.).</li>
                        </ul>
                        </div>
                        <br/>
                        <h3><u>How We Use Your Personal Data</u></h3>
                        <div>We will only use your personal data for the purpose for which We collected it which may include
                        but is not limited to the following:</div>

                        <ol>
                            <li>to register you as a new user.</li>
                            <li>to process and deliver your order.</li>
                            <li>to manage your relationship with us;</li>
                            <li>to recommend products or services which may be of interest to you;</li>
                            <li>to help us identify and provide the type of service offerings in which you are most interested
                        in;</li>
                            <li>to enable us create the content most relevant to you and to generally improve Our services;</li>
                            <li>to make the Platform easier for you to use by not having to enter information more than once;</li>
                            <li>to manage risk, or to detect, prevent, and/or remediate fraud or other potentially prohibited or
                        illegal activities; and</li>
                            <li>to send periodic emails and marketing materials regarding your interest in Our products and
                        services.</li>
                            </ol>


                        <h4><u>Third Party Links</u></h4>
                        <div>Occasionally, we may include or offer third party products or services on Our Platform. As such,
                        Our Platform may contain links to websites owned and operated by third parties. These
                        third-party websites may have their own separate and independent privacy policies, which may
                        govern the collection and processing of your personal data. We shall have no responsibility or
                        liability for the content, activities and privacy practices of such third-party websites.</div>

<br/>
                        <h4><u>Cookies</u></h4>
                        <div>A “cookie is a small piece of information stored by a web server on a web browser so that it can
                        be later read from the browser. These cookies allow us to uniquely identify your browser and to
                        distinguish you from other users of Our Website. This enables us to track your preferences and
                        provide you with a personalized and smooth experience when you browse Our Website.</div>
                        <br/>
                        <h4><u>User Profiles</u></h4>
                        <div>Every registered user has a unique personal profile. Each profile is assigned a unique personal
                        identification number, which helps us ensure that only you can access your profile. When you
                        register, We create your profile, assign a personal identification number, (your User ID) then send this personal identification number back to your email address with an
                        activation link for the activation of your profile. This code is uniquely yours. It is your
                        passport to seamless travel across Our Platform, allowing you to use Our Platform without having
                        to fill out registration forms with information you have already provided. Even if you switch
                        computers, you will not have to re-register – just use your User ID to identify yourself.</div>

<br/>
                        <h4><u>Data Security</u></h4>
                        <div>We store and process your personal information on our computers and servers in Nigeria. Where We
                        need to transfer your data to another country, such country must have an adequate data
                        protection law. Please be assured that We have put in place appropriate security measures
                        including but not limited to access controls, firewalls, data encryption, and physical security
                        to prevent your personal data from being lost, altered, disclosed, or otherwise used in an
                        unauthorized way. Also note that We have put in place procedures to deal with any suspected
                        personal data breach and will notify you and any applicable regulator of a breach where We are
                        legally required to do so.</div>


                        <br/>
                        <h4><u>Retention of Personal Information</u></h4>
                        <div>We will keep your personal information for as long as it is required and in accordance with the
                        purpose it is being processed for, or for tax, accounting, regulatory, or legal purposes. We
                        will keep the personal data for a period which enables us to handle or respond to any
                        complaints, queries or concerns relating to Our relationship with you. Related to this, We may
                        retain your personal data for a longer period in the event of a complaint or if We reasonably
                        believe there is a prospect of litigation in respect to Our relationship with you.</div>

<br/>
                        <div>You may notify us whenever you no longer wish to hear from us and We will keep minimum
                        information upon receipt of such notice to ensure that no future contact is made by us. We will
                        actively review the personal information We hold and delete it securely, or in some cases
                        anonymize it, when there is no longer a legal, business or user need for it to be retained.</div>


                        <br/><h4><u>Your Legal Rights</u></h4>
                        <div>You have the right to:</div>

                        <ol>
                            <li>request access to your personal data (commonly known as a "data subject access request"). This
                        enables you to receive a copy of the personal information We hold about you and check that We
                        are lawfully processing it. Please note that a data subject access request may attract an
                                administrative fee.</li>

                        <li>request correction of the personal data that We hold about you. This enables you to have any
                        incomplete or inaccurate data We hold about you corrected, though We may need to verify the
                        accuracy of the new data you provide to us.</li>

                        <li>request erasure of your personal data. This enables you to ask us to delete or remove personal
                        data where there is no good reason for us continuing to process it. You also</li>

                        <li>have the right to ask us to delete or remove your personal data where you have successfully
                        exercised your right to object to processing (see below), where We may have processed your
                        information unlawfully or where We are required to erase your personal data to comply with local
                        law. Note however, that We may not always be able to comply with your request of erasure for
                        specific legal reasons which will be notified to you, if applicable, at the time of your
                        request.</li>

                        <li>object to processing of your personal data where We are relying on a legitimate interest (or
                        those of a third party) and there is something about your particular situation which makes you
                        want to object to processing on this ground as you feel it impacts on your fundamental rights
                        and freedoms. You also have the right to object where We are processing your personal data for
                        direct marketing purposes. In some cases, We may demonstrate that We have compelling legitimate
                        grounds to process your information which override your rights and freedoms;</li>

                        <li>request the transfer of your personal data to you or to a third party. We will provide you, or a
                        third party you have chosen, your personal data in a structured, commonly used, machine-readable
                        format. Note that this right only applies to automated information which you initially provided
                        consent for us to use or where We used the information to perform a contract with you. Please
                        note that this request may also attract an administrative fee;</li>

                        <li>withdraw consent at any time where We are relying on consent to process your personal data.
                        However, this will not affect the lawfulness of any processing carried out before you withdraw
                        your consent. If you withdraw your consent, We may not be able to provide certain products or
                        services to you. We will advise you if this is the case at the time you withdraw your consent.</li>
                        </ol>


                        <br/>
                        <h4><u>Security</u></h4>
                        <div>At Getpouch, we implement a variety of reasonable security measures to protect the security and
                        integrity of your personal information.</div><br/>


                        <div>To prevent unauthorized access to your information, we have implemented strong controls and
                        security safeguards at the technical and operational levels. Our Platform uses Transport Layer
                        Security (SSL/TLS) to ensure secure transmission of your personal data. You should see the
                        padlock symbol in your URL address bar once you are successfully logged into the platform. The
                        URL address will also start with https:// depicting a secure Webpage.</div><br/>


                        <div>Please note that you also have a significant role in protecting your information. No one can see
                        or edit your personal information without knowing your username and password, so do not share
                        these with others. However, as the internet is not a secure medium, we cannot guarantee that
                        security breaches will never occur. Accordingly, we are not responsible for the matters, which
                        include actions of hackers and other unauthorized third parties that breach Our reasonable
                        security procedure.</div><br/>


                        <br/>
                        <h4><u>DISCLAIMER</u></h4>
                        <div>By this Policy We do not represent or warrant the condition or functionality of Our platform(s),
                        its suitability for use, nor guarantee that Our service will be free from interruption or any
                        error. No liability or responsibility shall lie for risks associated with the use of Our
                        Platform, including but not limited to any risk to your computer, software or data being damaged
                        by any virus, software, or any other file that might be transmitted or activated via Our
                        Platform or your access to it. Neither do We guarantee the reliability of information contained
                        on Our Platform—particularly those shared by third party users.</div>


                        <br/>
                        <h4><u>Contact Us</u></h4>
                        <div>If you have any questions about this Privacy Policy, please contact us at hello@getpouch.africa</div>
                    </div>

                </Row>
            </Container>
        </div>
    )
}

export default PolicyDocument