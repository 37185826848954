import React from 'react';
import 'assets/scss/main.scss';
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import Landing from 'Pages/Landing';
import Policy from 'Pages/Policy';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/privacy" element={<Policy />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
